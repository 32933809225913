




















































































































import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";
import SmallCard from "@/components/_cards/small-card.vue";
import charts from "@/mixin/charts-mixin";
import UXTechnicianService from "@/services/ux/uxtechnicians";
import BarChart from "@/components/charts/bar-chart.vue";
import PieChart from "@/components/charts/pie-chart.vue";
import LineChart from "@/components/charts/line-chart.vue";

let global = new GlobalServices();
let tech = new UXTechnicianService();
//Standard Components
import EVENTS from "@/constants/events";

export default {
  mixins: [charts],
  data() {
    return {
      listDataObj: {},
      EVENT_ACTION: {},
      actionId: null,
      id: null,
      parentActionId: null,
      moduleName: null,

      dropDownList: [
        {displayName: "Website", id: 1},
        {displayName: "Email", id: 2},
        {displayName: "Referral", id: 3},
      ],
      selectedRadio: "thismonth",
      options: [
        {text: "This Week", value: "thisweek"},
        {text: "Last Week", value: "lastweek"},
        {text: "This Month", value: "thismonth"},
        {text: "This Year", value: "thisyear"},
      ],
      mockStats: [
        {
          id: "1116b3e6-cc45-4c2d-8772-d086db31b0ec",
          displayTitle: "Pipeline Value",
          primaryNumber: "0",
          primarySymbol: "$",
          secondaryNumber: "",
          secondarySymbol: "",
          queryStringParamater: "1",
          displayDescription: "",
          color: "purple",
        },
        {
          id: "1116b3e6-cc45-4c2d-8772-d086db31b0ec",
          displayTitle: "Sent to Client",
          primaryNumber: "0",
          primarySymbol: "$",
          secondaryNumber: "",
          secondarySymbol: "",
          queryStringParamater: "1",
          displayDescription: "",
          color: "green",
        },
        {
          id: "1116b3e6-cc45-4c2d-8772-d086db31b0ec",
          displayTitle: "Approved Quotes",
          primaryNumber: "0",
          primarySymbol: "$",
          secondaryNumber: "",
          secondarySymbol: "",
          queryStringParamater: "1",
          displayDescription: "",
          color: "orange",
        },
        {
          id: "1116b3e6-cc45-4c2d-8772-d086db31b0ec",
          displayTitle: "Average Quote Approval Time",
          primaryNumber: "0",
          primarySymbol: "h",
          secondaryNumber: "",
          secondarySymbol: "",
          queryStringParamater: "1",
          displayDescription: "",
          color: "orange",
        },
      ],
      MockChartData: {
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        datasets: [
          {
            label: "All Technicians",
            backgroundColor: "#27ae5f  ",
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
            borderRadius: 4,
          },
        ],
      },
      headers: [
        {
          id: 1,
          title: "Technician",
          columnName: "employeeName",
          isSortable: true,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 2,
          title: "Time In",
          columnName: "timeIn",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 3,
          title: "Travel",
          columnName: "total",
          isSortable: true,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 4,
          title: "Time Out",
          columnName: "timeOut",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 5,
          title: "Total",
          columnName: "total",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 6,
          title: "Cost",
          columnName: "unitPrice",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 7,
          title: "Billable",
          columnName: "total",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
        },
      ],
      items: [],
      uxTechniciansList: [],
      technicianSelected: null,
      uxClientsList: [],
      clientSelected: null,
      uxQuoteStatus: [],
      statusSelected: null,
      barDataSets: {},
      barClientsDataSets: {},
      pieDataSets: {},
      lineChartDataSets: {},
    };
  },
  components: {
    SmallCard,
    // GenericTable,
    BarChart,
    PieChart,
    LineChart,
  },
  watch: {
    selectedRadio(value) {
      if (value) {
        this.reloadCharts();
        this.getUtilizationReport();
      }
    },
  },
  methods: {
    async loadData() {
      this.id = 0;
      this.parentActionId = 23;
      this.moduleName = "Time Tracking";
      this.actionId = 86;
      this.uxTechniciansList = await tech.getTechnicians();
      this.uxClientsList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);
      this.uxQuoteStatus = await global.getUXDropDown(MODULE.QUOTATION.UX.QuoteStatus);
      this.reloadData();
    },
    reloadData() {
      this.reloadCharts();
      this.getUtilizationReport();
    },
    async getUtilizationReport() {
      const params = {
        actionId: MODULE.QUOTATION.ActionId,
        moduleId: MODULE.PROJECTS.ModuleId,
        DateFilter: this.selectedRadio,
      } as any;

      if (this.clientSelected) {
        params.clientId = this.clientSelected;
      }

      if (this.technicianSelected) {
        params.technicianId = this.technicianSelected;
      }

      if (this.statusSelected) {
        params.statusId = this.statusSelected;
      }

      const results = await global.getStatsReportDataByActionId(params);
      this.mockStats = results;
    },
    async reloadCharts() {
      const allowedFilter = true;
      const params = {
        actionId: MODULE.QUOTATION.ActionId,
        moduleId: MODULE.PROJECTS.ModuleId,
        DateFilter: this.selectedRadio,
      } as any;

      if (allowedFilter) {
        const additionalParams = {} as any;
        if (this.clientSelected) {
          additionalParams.ClientId = this.clientSelected;
        }

        if (this.technicianSelected) {
          additionalParams.EmployeeId = this.technicianSelected;
        }

        if (this.statusSelected) {
          additionalParams.statusId = this.statusSelected;
        }

        this.barDataSets = await this.getChartsData({...params, ...additionalParams}, "bar", "SalesRep", false, ["Sales Representative"]);
        this.pieDataSets = await this.getChartsData({...params, ...additionalParams}, "pie", "Status");
      }

      this.lineChartDataSets = await this.getChartsData(params, "line", "Dates", true, ["Quote Amount"]);
      this.barClientsDataSets = await this.getChartsData(params, "bar", "Client", true, ["Clients"]);
    },
  },
  async mounted() {
    await this.loadData();
    //Reload Data every 5 seconds
    this.timer = setInterval(async () => {
      await this.reloadCharts();
    }, 5000);
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = false;
  },
  created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;
  },
};
